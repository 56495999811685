<template>
  <div class="success-view">
    <h1>Thank you for signing up!</h1>
    <v-row justify="content">
      <v-col class="mt-10">
        <v-btn color="#84C9C8" dark large rounded :to="{ name: 'Login' }">Login</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Success',

  computed: {
    ...mapGetters({
      charities: 'charity/list',
      participants: 'participant/list',
    }),

    participant() {
      return this.participants[0]
    },
  },

  beforeMount() {
    this.$store.dispatch('participant/patch', [this.participant.id, {
      hasPaid: true,
    }])
  }
}
</script>

<style lang="scss">

</style>